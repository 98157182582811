import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import fps from "../../Assets/Projects/fps.jpg";
import logos from "../../Assets/Projects/logos.jpg";
import vattara from "../../Assets/Projects/vattara.jpg";
import hextech from "../../Assets/Projects/hextech.jpg";
import merxsea from "../../Assets/Projects/merxsea.jpg";
import spark from "../../Assets/Projects/spark.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on .
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={merxsea}
              isBlog={false}
              title="Merxsea - Oil & Gas Industry Website Design"
              // description="Created a series of custom logos for various brands, showcasing a range of styles and design approaches. Focused on crafting unique visual identities to enhance brand recognition."
              ghLink="https://www.behance.net/gallery/211972927/Merxsea-Oil-Gas-Industry-Website-Design"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vattara}
              isBlog={false}
              title="Vattara - Real Estate App UX Design"
              // description="Vattara is a real estate app focused on simplifying property searches, listings, and buyer-seller communication. This project highlights the UI/UX design, emphasizing intuitive navigation, user journeys, key features, and design elements like typography, icons, and color schemes."
              ghLink="https://www.behance.net/gallery/208542787/Vattara-Real-Estate-App-UX-Design"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={spark}
              isBlog={false}
              title="Spark Station Logo Design"
              // description="Created a series of custom logos for various brands, showcasing a range of styles and design approaches. Focused on crafting unique visual identities to enhance brand recognition."
              ghLink="https://www.behance.net/gallery/212162805/Spark-Station-Logo-Design"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fps}
              isBlog={false}
              title="Friends Picture Studio - Logo & Branding Design"
              // description="Designed for Friends Picture Studio, including a logo and visiting card. Specializes in photography and videography for events and general services."
              ghLink="https://www.behance.net/gallery/206134115/Friends-Picture-Studio-Logo-Branding-Design"
              // demoLink="https://chatify-49.web.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hextech}
              isBlog={false}
              title="Hextech LTD – Mobile Service Website"
              // description="Created a series of custom logos for various brands, showcasing a range of styles and design approaches. Focused on crafting unique visual identities to enhance brand recognition."
              ghLink="https://www.behance.net/gallery/211948303/Hextech-LTD-Mobile-Service-Website"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={logos}
              isBlog={false}
              title="Logo Design Collection"
              // description="Created a series of custom logos for various brands, showcasing a range of styles and design approaches. Focused on crafting unique visual identities to enhance brand recognition."
              ghLink="https://www.behance.net/gallery/206146137/Collection-of-Logos"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
