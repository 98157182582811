import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am{" "}
            <span className="purple">Prathap Kalaivanan </span>
            from <span className="purple"> Chennai, India.</span>
            <br />
            I am a passionate and committed UI/UX designer with a Bachelor’s
            degree in Visual Communication from Bharath Institute of Higher
            Education & Research. With a year of experience in crafting
            user-centered, impactful designs for web and mobile applications,
            I’m eager to bring my skills and creativity to a team where I can
            contribute meaningfully and grow professionally.
            <br />
            <br />
            Apart from designing, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring new design trends
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "#0086ce" }}>
            "Design is not just what it looks like and feels like. Design is how
            it works and connects with people."{" "}
          </p>
          <footer className="blockquote-footer">Prathap</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
